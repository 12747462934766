



















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import coreApiWorkerClient from "@/services/apis/coreApiWorkerClient";
import makeSelectOptions from "@common/util/makeSelectOptions";
import orderTelesaleStatusesEn from "@/constants/orderTelesaleStatusesEn";
import orderLocationStatusesEn from "@/constants/orderLocationStatusesEn";
import orderCustomerRefusedStatusesEn from "@/constants/orderCustomerRefusedStatusesEn";
import userManager from "@/services/userManager";
import DataContainer from "@vuetifyx/common/DataContainer";
import ProductOrCombo from "./partials/ProductOrCombo.vue";
import moment from "moment";

export default Vue.extend({
  components: {
    ProductOrCombo,
  },
  data() {
    return {
      self: this,
      market: null,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(market) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            orderId: {
              text: "Order ID",
              sortable: true,
              width: "100px",
            },
            createdTime: {
              text: "Date Order",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            callTime: {
              text: "Call Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            productOrCombo: {
              text: "Product / Combo",
            },
            giftedProduct: {
              text: "Gifted Product",
              options: {
                subProp: "giftedProduct.sku",
                label: true,
                sortBy: "giftedProduct.name",
              },
            },
            singlePrice: {
              text: "COD Price",
              options: {
                preTransform(_, item) {
                  let value = (item.product && item.product.localPrice) || (item.productCombo && item.productCombo.overriddenLocalPrice) || item.productCombo?.localPrice;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            count: {
              text: "Count",
              sortable: true,
            },
            totalPrice: {
              text: "Total Price",
              options: {
                preTransform(_, item) {
                  let value = (item.product && item.product.localPrice) || (item.productCombo && item.productCombo.overriddenLocalPrice) || item.productCombo?.localPrice;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value * item.count;
                },
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            customerName: {
              text: "Customer's Name",
              sortable: true,
            },
            customerEmail: {
              text: "Email",
              sortable: true,
            },
            customerPhone: {
              text: "Phone Number",
              sortable: true,
            },
            customerMessage: {
              text: "Message",
              sortable: true,
            },
            customerState: !userManager.checkRole(["content", "design"], true) && {
              text: "State",
            },
            customerCity: !userManager.checkRole(["content", "design"], true) && {
              text: "City",
            },
            customerDistrict: !userManager.checkRole(["content", "design"], true) && {
              text: "District",
            },
            customerWard: !userManager.checkRole(["content", "design"], true) && {
              text: "Ward",
            },
            customerAddress: !userManager.checkRole(["content", "design"], true) && {
              text: "Address",
            },
            customerPostalCode: !userManager.checkRole(["content", "design"], true) && {
              text: "Postal Code",
            },
            telesaleUser: {
              text: "User Telesale",
              options: {
                subProp: "telesaleUser.name",
                sortBy: "telesaleUserId",
                attrs(_, item) {
                  const attrs: any = {};
                  if (item.telesaleUser && item.telesaleUser.ht === "nv") {
                    attrs.class = "red--text";
                  }
                  return attrs;
                },
              },
            },
            telesaleStatus: {
              text: "Calling Status",
              sortable: true,
              options: {
                label(value) {
                  return orderTelesaleStatusesEn[value || ""];
                },
              },
            },
            locationStatus: {
              text: "Address Status",
              sortable: true,
              options: {
                label(value) {
                  return orderLocationStatusesEn[value || ""];
                },
              },
            },
            locationNote: {
              text: "Address Note",
            },
            customerRefusedStatus: {
              text: "Cancel Reason",
              sortable: true,
              options: {
                label(value) {
                  return orderCustomerRefusedStatusesEn[value || ""];
                },
              },
            },
            notAnswerCallCount: {
              text: "Not Answer Count",
            },
            telesaleNote: {
              text: "Telesale Note",
            },
            action: {},
          },
          filters: {
            colLength: 2,
            filters: {
              orderId: {
                attrs: {
                  label: "Order ID",
                },
                rules: {
                  equal_to: {},
                },
              },
              "productItems.product._id": {
                type: "XAutocomplete",
                attrs: {
                  label: "Product",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in_id: {},
                },
              },
              "productCombos._id": {
                type: "XAutocomplete",
                attrs: {
                  label: "Combo",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productCombos", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "marketId",
                                operator: "equal_to",
                                value: market._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              "productItems.product.productGroupId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Product Group",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Date Order",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Date Order (Start)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                    ext: {
                      defaultValue: moment().subtract(3, "months").startOf("day").valueOf(),
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Date Order (End)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              lastTelesaleStatusUpdatedTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Call Time",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Call Time (Start)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Call Time (End)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              customerName: {
                attrs: {
                  label: "Customer's Name",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerEmail: {
                attrs: {
                  label: "Email",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerPhone: {
                attrs: {
                  label: "Phone Number",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerMessage: {
                attrs: {
                  label: "Message",
                },
                rules: {
                  equal_to: {},
                },
              },
              locationNoteExist: {
                type: "select",
                attrs: {
                  label: "Address Note Exists",
                  items: [
                    { value: "", text: "Trống" },
                    { value: "yes", text: "Có" },
                    { value: "no", text: "Không" },
                  ],
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value === "yes") {
                        return [
                          {
                            key: "locationNote",
                            operator: "not_equal_to",
                            value: "",
                          },
                          {
                            key: "locationNote",
                            operator: "has_property",
                          },
                        ];
                      } else if (value === "no") {
                        return {
                          operator: "or",
                          subFilters: [
                            {
                              key: "locationNote",
                              operator: "equal_to",
                              value: "",
                            },
                            {
                              key: "locationNote",
                              operator: "not_has_property",
                            },
                          ],
                        };
                      }
                      return [];
                    },
                  },
                },
              },
              telesaleUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User Telesale",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return [
                          {
                            _id: "",
                            name: "Trống",
                          },
                        ].concat(items);
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
                allowEmpty: true,
              },
              telesaleStatus: {
                type: "selectMultiple",
                attrs: {
                  label: "Calling Status",
                  items: makeSelectOptions(orderTelesaleStatusesEn),
                },
                rules: {
                  in: {},
                },
              },
              telesaleNote: {
                attrs: {
                  label: "Telesale Note",
                },
                rules: {
                  equal_to: {},
                },
              },
              notAnswerCallCount: {
                type: "integer",
                attrs: {
                  label: "Not Answer Count",
                },
                rules: {
                  equal_to: {},
                },
              },
              locationStatus: {
                type: "selectMultiple",
                attrs: {
                  label: "Address Status",
                  items: makeSelectOptions(orderLocationStatusesEn),
                },
                rules: {
                  in: {},
                },
              },
              customerRefusedStatus: {
                type: "selectMultiple",
                attrs: {
                  label: "Cancel Reason",
                  items: [
                    {
                      text: "Trống",
                      value: "",
                    },
                  ].concat(makeSelectOptions(orderCustomerRefusedStatusesEn)),
                },
                rules: {
                  in: {},
                },
              },
              customerMessageExist: {
                type: "select",
                attrs: {
                  label: "Customer Message Exists",
                  items: [
                    { value: "", text: "Trống" },
                    { value: "yes", text: "Có" },
                    { value: "no", text: "Không" },
                  ],
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value === "yes") {
                        return [
                          {
                            key: "customerMessage",
                            operator: "not_equal_to",
                            value: "",
                          },
                          {
                            key: "customerMessage",
                            operator: "has_property",
                          },
                        ];
                      } else if (value === "no") {
                        return {
                          operator: "or",
                          subFilters: [
                            {
                              key: "customerMessage",
                              operator: "equal_to",
                              value: "",
                            },
                            {
                              key: "customerMessage",
                              operator: "not_has_property",
                            },
                          ],
                        };
                      }
                      return [];
                    },
                  },
                },
              },
              mktUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User MKT",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                              {
                                key: "roles",
                                operator: "in",
                                value: ["mkt", "tp", "rd", "rdl"],
                              },
                            ],
                          }),
                        });
                        return [
                          {
                            _id: "",
                            name: "Trống",
                          },
                        ].concat(items);
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              customerIpCoincide: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "boolean",
                  attrs: {
                    label: "IP trùng so với tất cả các đơn hàng",
                  },
                  rules: {
                    equal_to: {},
                  },
                },
            },
          },
          topActionButtons: {
            export: userManager.checkRole(["kt", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Export Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (findAllOptions.extraParams) {
                    findAllOptions.extraParams = {};
                  }
                  findAllOptions.extraParams.fieldSet = "telesale";
                  findAllOptions.extraParams.marketCode = market.code;

                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                        extraParams: {
                          fieldSet: "telesale",
                        },
                      }),
                    };
                  }
                  coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                },
              },
            },
            importTransportStatus: userManager.checkRole(["kt", "telesale"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                icon: "mdi-upload",
                text: "Import",
              },
              target: {
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Import",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Upload",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "orders",
                                  "import",
                                  {
                                    fieldSet: "telesale",
                                    marketId: market._id,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          multiSelectActionButtons: {
            selectTelesaleUserId: userManager.checkRole(["kt", "telesale", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Select Telesale",
                icon: "mdi-phone",
              },
              target: {
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Select telesale",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  telesaleUserId: (userManager.checkRole("telesale", true) && {
                                    type: "select",
                                    attrs: {
                                      label: "User Telesale",
                                      items: [
                                        {
                                          text: userManager.getUserInfo().name,
                                          value: userManager.getUserInfo()._id,
                                        },
                                      ],
                                      readonly: true,
                                    },
                                    ext: {
                                      defaultValue: userManager.getUserInfo()._id,
                                    },
                                  }) || {
                                    type: "XAutocomplete",
                                    attrs: {
                                      label: "User Telesale",
                                      required: true,
                                      "item-value": "_id",
                                      "item-text": "name",
                                      xOptions: {
                                        content: {
                                          async itemsSuggestor(search, value, limit) {
                                            const { items } = await coreApiClient.call("users", "findAll", {
                                              payload: JSON.stringify({
                                                limit,
                                                search,
                                                filters: [
                                                  {
                                                    key: "roles",
                                                    operator: "equal_to",
                                                    value: "telesale",
                                                  },
                                                  {
                                                    key: "_id",
                                                    operator: "equal_to",
                                                    value,
                                                  },
                                                ],
                                              }),
                                            });
                                            return items;
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      cancel: {
                        content: {
                          text: "Close",
                        },
                      },
                      save: {
                        content: {
                          text: "Update",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            const ids = dataTable.model.value.map((item) => item._id);
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "orders",
                                  "updateMany",
                                  {},
                                  {
                                    ids,
                                    data: {
                                      telesaleUserId: data.telesaleUserId,
                                    },
                                    updateRequest: {
                                      scenario: "TelesaleCheckSetTelesaleUserId",
                                    },
                                  }
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            setHt: userManager.checkRole(["kt", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Update Status to Complete",
                icon: "mdi-check",
              },
              target: {
                confirm: {
                  content: {
                    content: {
                      html: "Confirm?",
                    },
                  },
                  on: {
                    async xYes({ self: dialog }) {
                      const yesBtn = dialog.yesBtnContainer.value;
                      const btn = dialog.context();
                      const dataTable = btn.context();
                      const ids = dataTable.model.value.map((item) => item._id);
                      yesBtn.loading.value = true;
                      const result = await dataTable.execute(
                        async () => {
                          return await coreApiClient.call(
                            "orders",
                            "updateMany",
                            {},
                            {
                              ids,
                              data: {
                                telesaleStatus: "ht",
                              },
                              updateRequest: {
                                scenario: "TelesaleCheckSetTelesaleStatusHt",
                              },
                            }
                          );
                        },
                        undefined,
                        {
                          disableLoading: true,
                          willRefresh: true,
                        }
                      );
                      yesBtn.loading.value = false;
                      if (result) {
                        dialog.hide();
                      }
                    },
                  },
                },
              },
            },
            setH: userManager.checkRole(["kt", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Update Status to Cancel",
                icon: "mdi-close",
              },
              target: {
                confirm: {
                  content: {
                    content: {
                      html: "Confirm?",
                    },
                  },
                  on: {
                    async xYes({ self: dialog }) {
                      const yesBtn = dialog.yesBtnContainer.value;
                      const btn = dialog.context();
                      const dataTable = btn.context();
                      const ids = dataTable.model.value.map((item) => item._id);
                      yesBtn.loading.value = true;
                      const result = await dataTable.execute(
                        async () => {
                          return await coreApiClient.call(
                            "orders",
                            "updateMany",
                            {},
                            {
                              ids,
                              data: {
                                telesaleStatus: "h",
                                status: "h",
                              },
                              updateRequest: {
                                scenario: "TelesaleCheckSetTelesaleStatusH",
                              },
                            }
                          );
                        },
                        undefined,
                        {
                          disableLoading: true,
                          willRefresh: true,
                        }
                      );
                      yesBtn.loading.value = false;
                      if (result) {
                        dialog.hide();
                      }
                    },
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "telesale", "vdl", "vd"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    content: {
                      content: {
                        xOptions: {
                          content: {
                            colLength: 4,
                          },
                        },
                      },
                      buttons: {
                        cancel: {
                          content: {
                            text: "Close",
                          },
                        },
                        save: {
                          content: {
                            text: "Update",
                          },
                        },
                      },
                    },
                    ext: {
                      subTitleMaker: "orderId",
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            if (userManager.checkRole(["telesale"], true)) {
              options.hint = "marketId_1_telesaleStatus_1_telesaleUserId_1_createdTime_-1";
            } else {
              options.hint = "marketId_1_telesaleStatus_1_invalid_1_createdTime_-1";
            }
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: this.context().$route.params.marketId,
            });
            options.filters.push({
              key: "invalid",
              operator: "equal_to",
              value: false,
            });
            options.filters.push({
              key: "telesaleStatus",
              operator: "in",
              value: ["kt", "ssdt", "sdttb", "kknm", "k"],
            });
            options.filters.push({
              operator: "or",
              subFilters: [
                {
                  key: "status",
                  operator: "in",
                  value: ["kt", "cbpu", "cpu", "cxk"],
                },
                {
                  key: "customerRefusedStatus",
                  operator: "in",
                  value: ["ipkqg", "ipblocked", "iptgd", "nameblocked", "phoneblocked"],
                },
              ],
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("orders", "findAll", options);
              return [items, count];
            },
            async update(item) {
              return await coreApiClient.call(
                "orders",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                  updateRequest: {
                    scenario: "TelesaleCheckItemUpdate",
                  },
                }
              );
            },
          },
          default: {
            defaultInputs: {
              productOrCombo: {
                attrs: {
                  label: "Product / Combo",
                  readonly: true,
                  xOptions: {
                    type: ProductOrCombo,
                    attrs(value, _item, self) {
                      const item = self.context().context().xData.value;
                      return {
                        item,
                      };
                    },
                  },
                },
                content: {
                  static: true,
                },
                ext: {
                  colLength: 6,
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              orderId: {
                attrs: {
                  label: "Order ID",
                  readonly: true,
                },
              },
              telesaleUserId: (userManager.checkRole(["telesale"], true) && {
                type: "select",
                attrs: {
                  label: "User Telesale",
                  items: [
                    {
                      text: userManager.getUserInfo().name,
                      value: userManager.getUserInfo()._id,
                    },
                  ],
                  readonly: true,
                  required: true,
                },
                ext: {
                  defaultValue: userManager.getUserInfo()._id,
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return !item.telesaleUserId;
                  },
                },
              }) || {
                type: "XAutocomplete",
                attrs: {
                  label: "User Telesale",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "roles",
                                operator: "equal_to",
                                value: "telesale",
                              },
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              customerName: {
                attrs: {
                  label: "Customer's Name",
                  readonly: true,
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              customerPhone: {
                attrs: {
                  label: "Phone Number",
                  readonly: true,
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Date Order",
                  xOptions: {
                    content: {
                      dpAttrs: {
                        readonly: true,
                      },
                    },
                  },
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              singlePrice: {
                attrs: {
                  label: "COD Price",
                  readonly: true,
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                  valueGetter(item) {
                    let value = (item.product && item.product.localPrice) || (item.productCombo && item.productCombo.overriddenLocalPrice) || item.productCombo?.localPrice;
                    if (isNaN(value)) {
                      value = 0;
                    }
                    return value;
                  },
                },
              },
              blank1: { ext: { blank: true, colLength: 6 } },
              telesaleStatus: {
                type: "select",
                attrs: {
                  label: "Calling Status",
                  items: makeSelectOptions(orderTelesaleStatusesEn),
                },
                ext: {
                  defaultValue: "",
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              telesaleNote: {
                // type: "textLarge",
                attrs: {
                  label: "Telesale Note",
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              customerRefusedStatus: {
                type: "select",
                attrs: {
                  label: "Cancel Reason",
                  items: makeSelectOptions(orderCustomerRefusedStatusesEn),
                  required: true,
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const telesaleStatus = form.inputs.telesaleStatus && form.inputs.telesaleStatus.value;
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId && telesaleStatus === "h";
                  },
                },
              },
              additionalOrderNeed: {
                type: "boolean",
                attrs: {
                  label: "Add Order",
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              notAnswerCallCount: {
                type: "integer",
                attrs: {
                  label: "Not Answer Call Count",
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                  defaultValue: 0,
                },
              },
              locationStatus: {
                type: "select",
                attrs: {
                  label: "Address Status",
                  items: makeSelectOptions(orderLocationStatusesEn),
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              locationNote: {
                // type: "textLarge",
                attrs: {
                  label: "Address Note",
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              customerState: {
                attrs: {
                  label: market.code.toLowerCase() === "ph" ? "Province" : "State",
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              customerDistrict: {
                attrs: {
                  label: "City",
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              customerWard: {
                attrs: {
                  label: market.code.toLowerCase() === "ph" ? "Barangay" : "Ward",
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              customerAddress: {
                attrs: {
                  label: "Detailed Address",
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              customerPostalCode: {
                attrs: {
                  label: "Postale Code",
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.telesaleUserId;
                  },
                },
              },
              productCombo: {
                type: "XAutocomplete",
                attrs: {
                  label: "Combo",
                  "item-value": "_id",
                  "item-text": "name",
                  "return-object": true,
                  clearable: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productCombos", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "marketId",
                                operator: "equal_to",
                                value: market._id,
                              },
                              {
                                key: "_id",
                                operator: "equal_to",
                                value: value && value._id,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  emptyValue: null,
                  condition() {
                    return market.code.includes("telesale") || market.code.includes("test-fb");
                  },
                },
              },
              productItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Product items",
                  xOptions: {
                    content: {
                      itemLabel: (item, i) => (item.product && item.product.sku) || `Sản phẩm ${i + 1}`,
                      itemProperties: {
                        product: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Sản phẩm",
                            "item-value": "_id",
                            "item-text": "sku",
                            "return-object": true,
                            clearable: true,
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("products", "findAll", {
                                    payload: JSON.stringify({
                                      search,
                                      limit,
                                      filters: [
                                        {
                                          key: "countryId",
                                          operator: "equal_to",
                                          value: market.countryId,
                                        },
                                        {
                                          key: "_id",
                                          operator: "equal_to",
                                          value: value && value._id,
                                        },
                                      ],
                                    }),
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                        },
                        count: {
                          type: "integer",
                          attrs: {
                            label: "Số lượng",
                            required: true,
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 6,
                          },
                        },
                      },
                    },
                  },
                },
                ext: {
                  colLength: 6,
                  condition() {
                    return market.code.includes("telesale") || market.code.includes("test-fb");
                  },
                },
              },
              overriddenSinglePrice: {
                type: "number",
                attrs: {
                  label: "Overridden Single Price",
                },
                ext: {
                  condition() {
                    return market.code.includes("telesale") || market.code.includes("test-fb");
                  },
                },
              },
            },
            editForm: userManager.checkRole(["kt", "telesale", "vdl", "vd"]) && {
              content: {
                fieldNames: [
                  "productOrCombo",
                  "createdTime",
                  "singlePrice",

                  "orderId",
                  "telesaleUserId",
                  "customerName",
                  "customerPhone",

                  "customerState",
                  "customerDistrict",
                  "customerWard",
                  "customerAddress",

                  "customerPostalCode",

                  "telesaleStatus",
                  "telesaleNote",
                  "locationStatus",
                  "locationNote",

                  // "notAnswerCallCount",
                  "additionalOrderNeed",
                  "customerRefusedStatus",

                  "productCombo",
                  "productItems",
                  "overriddenSinglePrice",
                ],
                colLength: 3,
              },
            },
          },
        },
      };
    },
  },
  async created() {
    this.market = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions(this.market);
  },
});
